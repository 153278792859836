import React from 'react';
import { Fade } from 'src/animations';
import { Section, SectionTitle } from 'src/component';
import { content } from 'src/config';
import { Service } from './Service';

const UnmemoWhatIDo: React.FC = () => {
  const { title, description, services } = content.home.whatIDo;

  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <SectionTitle
              title={title}
              subTitle={description}
              hasMarginBottom
            />
          </div>
        </div>
        <div className="row">
          {services.map(({ ...rest }, index) => (
            <div className="col-lg-4" key={index}>
              <Fade>
                <Service {...rest} />
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export const WhatIDo = React.memo(UnmemoWhatIDo);
