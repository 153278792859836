import React, { useEffect, useState } from 'react';
import { SubFooter } from 'src/component/SubFooter';
import { pageLinks } from 'src/config';
import { NavLink } from '../NavLink';
import classes from './Menu.module.scss';

type Props = {
  isActive: boolean;
};

const UnmemoMenu: React.FC<Props> = ({ isActive }) => {
  const [removeDelay, setRemoveDelay] = useState(false);

  useEffect(() => {
    if (isActive)
      setTimeout(() => {
        setRemoveDelay(true);
      }, 800);
    else
      setTimeout(() => {
        setRemoveDelay(false);
      }, 800);
  }, [isActive]);

  const extraClasses = `${isActive ? classes.active : ''} ${
    removeDelay ? classes.removeDelay : ''
  }`;

  return (
    <>
      <div className={`${classes.layer} ${extraClasses}`}></div>
      <div className={`${classes.wrapper} ${extraClasses}`}>
        <div className="container">
          <div className={classes.menu}>
            <div className={classes.list}>
              <NavLink to={pageLinks.home} text="Home" />
              <NavLink to={pageLinks.about} text="About" />
              <NavLink to={pageLinks.contact} text="Contact" />
              <NavLink to={pageLinks.blog} text="Blog" hasPadding />
            </div>
          </div>
          <SubFooter />
        </div>
      </div>
    </>
  );
};

export const Menu = React.memo(UnmemoMenu);
