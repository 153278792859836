import React from 'react';
import { SEOProperties, componentSizes, htmlTag } from 'src/types';
import { Link, Text } from 'src/widget';
import { pageLinks } from './app';
import { blogImages } from './images';

export type Blog = {
  title: string;
  description: string;
  date: string;
  image: string;
  link: string;
  detail: React.ReactNode;
  seoProperties: Omit<SEOProperties, 'title'>;
};

export const blogNames = {
  StateManagementInReactWithTypeScriptAComprehensiveGuide:
    'StateManagementInReactWithTypeScriptAComprehensiveGuide',
  EnhancingTypographyWithWebkitFontSmoothingAntialiased:
    'EnhancingTypographyWithWebkitFontSmoothingAntialiased',
  The7CSSMistakesWebDevelopersShouldAvoid:
    'The7CSSMistakesWebDevelopersShouldAvoid',
  HowToBecomeAFrontEndDeveloper: 'HowToBecomeAFrontEndDeveloper',
  HTMLMistakesWebDevelopersShouldAvoid: 'HTMLMistakesWebDevelopersShouldAvoid',
  The10MostCommonMistakesWebDevelopersMake:
    'The10MostCommonMistakesWebDevelopersMake',
  HowToCreateAProposalThatWinsJobs: 'HowToCreateAProposalThatWinsJobs',
  HowDoGetMoreLongTermClientsTipsForFreelancers:
    'HowDoGetMoreLongTermClientsTipsForFreelancers'
} as const;

export type BlogNames = (typeof blogNames)[keyof typeof blogNames];

export const blogList: BlogNames[] = [
  blogNames.StateManagementInReactWithTypeScriptAComprehensiveGuide,
  blogNames.EnhancingTypographyWithWebkitFontSmoothingAntialiased,
  blogNames.The7CSSMistakesWebDevelopersShouldAvoid,
  blogNames.HowToBecomeAFrontEndDeveloper,
  blogNames.HTMLMistakesWebDevelopersShouldAvoid,
  blogNames.The10MostCommonMistakesWebDevelopersMake,
  blogNames.HowToCreateAProposalThatWinsJobs,
  blogNames.HowDoGetMoreLongTermClientsTipsForFreelancers
];

export const blogs: { [key: string]: Blog } = {
  [blogNames.StateManagementInReactWithTypeScriptAComprehensiveGuide]: {
    title: 'State Management in React with TypeScript: A Comprehensive Guide',
    description:
      'An effective state management system is a key consideration...',
    date: 'Oct 10, 2024',
    link: '/state-management-in-react-with-typeScript',
    image: blogImages.StateManagementInReactWithTypeScriptAComprehensiveGuide,
    detail: (
      <>
        <Text>
          An effective state management system is a key consideration in any
          React application. Correctly Organizing state can reduce business
          application complexity - and when you combine this with the use of
          Typescript with React you should be able to get rid of errors and keep
          a consistent approach. In this article, we will delve into the
          different ways to implement state management in a React application
          with particular consideration on the merits brought forth by
          TypeScript.
        </Text>
        <Text tag={htmlTag.h2}>1. React's Built-In State Management</Text>
        <Text>
          React provides a simple way to manage local state using the useState
          and useReducer hooks. Let's see how to type these hooks correctly with
          TypeScript.
          <br />
          <br />
          <b>useState with TypeScript</b>
          <br />
          useState is the most basic way to manage state in React components.
          Here's an example of how you would use it with TypeScript:
        </Text>
        <code>
          {'import React, { useState } from "react";'}
          <br />
          <br />
          {'const Counter: React.FC = () => {'} <br />
          &nbsp;&nbsp;&nbsp;{'const [count, setCount] = useState<number>(0);'}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;{'return ('}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<div>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'<p>Count: {count}</p>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'<button onClick={() => setCount(count + 1)}>Increment</button>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</div>'}
          <br />
          &nbsp;&nbsp;&nbsp;{');'}
          <br />
          {'};'}
          <br />
          <br />
          {'export default Counter;'}
        </code>
        <Text>
          In this example, we're using {'useState<number>(0)'} to explicitly
          define the state type as number. This ensures that the count variable
          always holds a number and prevents potential type errors.
          <br />
          <br />
          <b>useReducer with TypeScript</b>
          <br />
          For more complex state logic, useReducer is a great alternative.
          Here’s how you can type the state and actions in TypeScript:
        </Text>
        <code>
          {'type CounterState = {'} <br />
          &nbsp;&nbsp;&nbsp;{'count: number;'}
          <br />
          {'};'}
          <br />
          <br />
          {
            'type CounterAction = { type: "increment" } | { type: "decrement" };'
          }
          <br />
          <br />
          {
            'const counterReducer = (state: CounterState, action: CounterAction): CounterState => {'
          }
          <br />
          &nbsp;&nbsp;&nbsp;{'switch (action.type) {'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'case "increment":'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'return { count: state.count + 1 };'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'case "decrement":'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'return { count: state.count - 1 };'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'default:'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'return state;'}
          <br />
          &nbsp;&nbsp;&nbsp;{'}'}
          <br />
          {'};'}
          <br />
          <br />
          {'const CounterWithReducer: React.FC = () => {'}
          <br />
          &nbsp;&nbsp;&nbsp;
          {
            'const [state, dispatch] = useReducer(counterReducer, { count: 0 });'
          }
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;{'return ('}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<div>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'<p>Count: {state.count}</p>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            '<button onClick={() => dispatch({ type: "increment" })}>Increment</button>'
          }
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            '<button onClick={() => dispatch({ type: "decrement" })}>Decrement</button>'
          }
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</div>'}
          <br />
          &nbsp;&nbsp;&nbsp;{');'}
          <br />
          {'};'}
          <br />
          <br />
          {'export default CounterWithReducer;'}
        </code>
        <Text>
          In this example, we define a CounterState type and a CounterAction
          union type for the actions. The reducer function then operates based
          on these types, ensuring that only the allowed actions and state types
          can be used.
        </Text>
        <Text tag={htmlTag.h2}>2. Context API for Global State</Text>
        <Text>
          The React Context API is useful when you need to share state across
          multiple components without prop drilling. Here's how to use the
          Context API with TypeScript:
        </Text>
        <code>
          {
            'import React, { createContext, useContext, useState, ReactNode } from "react";'
          }{' '}
          <br />
          <br />
          {'// Define the shape of the context'}
          <br />
          {'type AuthContextType = {'}
          <br />
          &nbsp;&nbsp;&nbsp;{'user: string | null;'}
          <br />
          &nbsp;&nbsp;&nbsp;{'login: (user: string) => void;'}
          <br />
          &nbsp;&nbsp;&nbsp;{'logout: () => void;'}
          <br />
          {'};'}
          <br />
          <br />
          {'// Create context with default values'}
          <br />
          {
            'const AuthContext = createContext<AuthContextType | undefined>(undefined);'
          }
          <br />
          <br />
          {
            'export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {'
          }
          <br />
          &nbsp;&nbsp;&nbsp;
          {'const [user, setUser] = useState<string | null>(null);'}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;{'const login = (user: string) => setUser(user);'}
          <br />
          &nbsp;&nbsp;&nbsp;{'const logout = () => setUser(null);'}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;{'return ('}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'<AuthContext.Provider value={{ user, login, logout }}>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{children}'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</AuthContext.Provider>'}
          <br />
          &nbsp;&nbsp;&nbsp;{');'}
          <br />
          {'};'}
          <br />
          <br />
          {'// Custom hook to use the auth context'}
          <br />
          {'export const useAuth = () => {'}
          <br />
          &nbsp;&nbsp;&nbsp;{'const context = useContext(AuthContext);'}
          <br />
          &nbsp;&nbsp;&nbsp;{'if (!context) {'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'throw new Error("useAuth must be used within an AuthProvider");'}
          <br />
          &nbsp;&nbsp;&nbsp;{'}'}
          <br />
          &nbsp;&nbsp;&nbsp;{'return context;'}
          <br />
          {'};'}
        </code>
        <Text>
          Here, the AuthContextType ensures that any components consuming the
          context will receive the correct types. The useAuth hook further
          simplifies access to this context and enforces that it is used within
          the correct provider.
        </Text>

        <Text tag={htmlTag.h2}>
          3. Managing Complex State with Redux and TypeScript
        </Text>
        <Text>
          When state grows beyond local or context-based management, Redux is
          often a preferred solution. TypeScript integrates well with Redux,
          providing type safety across actions, reducers, and state.
          <br />
          <br />
          <b>Defining State and Actions in Redux</b>
        </Text>
        <code>
          {'// Define action types'} <br />
          {'enum CounterActionTypes {'}
          <br />
          &nbsp;&nbsp;&nbsp;{'INCREMENT = "INCREMENT",'}
          <br />
          &nbsp;&nbsp;&nbsp;{'DECREMENT = "DECREMENT"'}
          <br />
          {'}'}
          <br />
          <br />
          {'// Define the shape of the state'}
          <br />
          {'interface CounterState {'}
          <br />
          &nbsp;&nbsp;&nbsp;{'count: number;'}
          <br />
          {'}'}
          <br />
          <br />
          {'// Define actions with types'}
          <br />
          {'interface IncrementAction {'}
          <br />
          &nbsp;&nbsp;&nbsp;{'type: CounterActionTypes.INCREMENT;'}
          <br />
          {'}'}
          <br />
          <br />
          {'interface DecrementAction {'}
          <br />
          &nbsp;&nbsp;&nbsp;{'type: CounterActionTypes.DECREMENT;'}
          <br />
          {'}'}
          <br />
          <br />
          {'type CounterAction = IncrementAction | DecrementAction;'}
          <br />
          <br />
          {'// Create a reducer with typed state and actions'}
          <br />
          {
            'const counterReducer = (state: CounterState = { count: 0 }, action: CounterAction): CounterState => {'
          }
          <br />
          &nbsp;&nbsp;&nbsp;{'switch (action.type) {'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'case CounterActionTypes.INCREMENT:'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'return { count: state.count + 1 };'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'case CounterActionTypes.DECREMENT:'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'return { count: state.count - 1 };'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'default:'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'return state;'}
          <br />
          &nbsp;&nbsp;&nbsp;{'}'}
          <br />
          {'};'}
          <br />
          <br />
          {'export default counterReducer;'}
        </code>
        <Text>
          In this Redux example, we define CounterState, CounterActionTypes, and
          CounterAction types to enforce type safety across the entire Redux
          flow. Using TypeScript with Redux ensures that action types and
          payloads are properly validated during development.
          <br />
          <br />
          <b>Typed Redux Store</b>
        </Text>
        <code>
          {'import { createStore } fromx"redux";'} <br />
          {'import { useSelector, useDispatch } from "react-redux";'}
          <br />
          {'import counterReducer from "./counterReducer";'}
          <br />
          <br />
          {'// Create store'}
          <br />
          {'const store = createStore(counterReducer);'}
          <br />
          <br />
          {'// Typed selectors and dispatch'}
          <br />
          {'const CounterComponent: React.FC = () => {'}
          <br />
          &nbsp;&nbsp;&nbsp;
          {'const count = useSelector((state: CounterState) => state.count);'}
          <br />
          &nbsp;&nbsp;&nbsp;{'const dispatch = useDispatch();'}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;{'return ('}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<div>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {'<p>Count: {count}</p>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            '<button onClick={() => dispatch({ type: CounterActionTypes.INCREMENT })}>Increment</button>'
          }
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            '<button onClick={() => dispatch({ type: CounterActionTypes.DECREMENT })}>Decrement</button>'
          }
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</div>'}
          <br />
          &nbsp;&nbsp;&nbsp;{');'}
          <br />
          {'};'}
          <br />
          <br />
          {'export default CounterComponent;'}
        </code>
        <Text>
          By using useSelector and useDispatch with TypeScript, we ensure that
          the state and actions are correctly typed, reducing the risk of
          runtime errors.
        </Text>
        <Text tag={htmlTag.h2}>4. Comparing State Management Options</Text>
        <table>
          <thead>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Approach</Text>
              </th>
              <th>
                <Text size={componentSizes.regular}>Use Case</Text>{' '}
              </th>
              <th>
                <Text size={componentSizes.regular}>Pros</Text>
              </th>
              <th>
                <Text size={componentSizes.regular}>Cons</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Text size={componentSizes.regular}>
                  useState and useReducer
                </Text>
              </td>
              <td>
                <Text>Local component state</Text>
              </td>
              <td>
                <Text>Simple, built-in, and no external dependencies</Text>
              </td>
              <td>
                <Text>Limited to local component scope</Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text size={componentSizes.regular}>Context API </Text>
              </td>
              <td>
                <Text>Sharing state across multiple components </Text>
              </td>
              <td>
                <Text>Built-in, avoids prop drilling </Text>
              </td>
              <td>
                <Text>Can become complex with large state</Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text size={componentSizes.regular}>Redux</Text>
              </td>
              <td>
                <Text>Managing large-scale application state </Text>
              </td>
              <td>
                <Text>Centralized state, predictable patterns </Text>
              </td>
              <td>
                <Text>Requires setup and boilerplate</Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text size={componentSizes.regular}>Zustand or Jotai </Text>
              </td>
              <td>
                <Text>Lightweight global state management solutions </Text>
              </td>
              <td>
                <Text>Minimal boilerplate, simple API </Text>
              </td>
              <td>
                <Text>Less mature ecosystem than Redux</Text>
              </td>
            </tr>
          </tbody>
        </table>

        <Text tag={htmlTag.h2}>Conclusion</Text>
        <Text>
          Choosing an appropriate state management strategy is based on the
          level of functionality of the application. React’s useState and
          useReducer hooks are great for simple local state. For global state,
          Context API with TypeScript works perfectly. But as your app extends,
          you may need to implements some other tools like Redux or other such
          state containers.
          <br />
          <br />
          In any case, ordering state management within the boundaries of
          TypeScript brings value and reduces errors because the management
          system is well defined thus enhancing the reliability and
          maintainability of React applications.
        </Text>
      </>
    ),
    seoProperties: {
      description:
        'When you combine this with the use of Typescript with React you should be able to get rid of errors and keep a consistent approach',
      keywords: ''
    }
  },
  [blogNames.EnhancingTypographyWithWebkitFontSmoothingAntialiased]: {
    title: 'Enhancing Typography with -webkit-font-smoothing: antialiased;',
    description:
      'In the world of web design, typography plays a crucial role...',
    date: 'May 21, 2023',
    link: '/enhancing-typography-with-webkit-font-smoothing-antialiased',
    image: blogImages.EnhancingTypographyWithWebkitFontSmoothingAntialiased,
    detail: (
      <>
        <Text>
          In the world of web design, typography plays a crucial role in
          delivering a visually appealing and readable user experience. While
          choosing the right font and size is important, there are additional
          techniques and properties that can further enhance the clarity and
          smoothness of text rendering on web pages. One such technique is the
          use of “-webkit-font-smoothing: antialiased;” property, which can
          significantly improve the visual quality of fonts on certain web
          browsers. In this blog post, we will explore the benefits of this
          property and how it can be implemented to elevate the typography on
          your website. I want to acknowledge{' '}
          <a href="https://mubasshir.me/">Mubashir‘s</a> significant
          contributions to this topic.
        </Text>
        <Text tag={htmlTag.h2}>Understanding Font Rendering</Text>
        <Text>
          Before delving into the specifics of -webkit-font-smoothing:
          antialiased, it is essential to grasp the concept of font rendering.
          When text is rendered on a digital screen, especially at smaller
          sizes, jagged edges and pixelation can occur, making the text appear
          less smooth and harder to read. This issue is particularly prominent
          on Windows-based systems.
        </Text>
        <Text tag={htmlTag.h2}>The -Webkit-Font-Smoothing Property</Text>
        <Text>
          The -webkit-font-smoothing property is a CSS extension primarily
          supported by WebKit-based browsers (such as Safari and Chrome) that
          aims to enhance the visual rendering of fonts. By applying
          “-webkit-font-smoothing: antialiased;”, you instruct the browser to
          apply anti-aliasing to the text, resulting in smoother edges and
          improved legibility.
        </Text>
        <Text tag={htmlTag.h2}>
          Benefits Of -Webkit-Font-Smoothing: Antialiased
        </Text>
        <Text>
          Implementing -webkit-font-smoothing: antialiased can yield several
          advantages:a. Enhanced Readability: The anti-aliasing technique
          employed by this property smooths out the jagged edges, making the
          text appear more crisp and readable.b. Improved Aesthetics: By
          reducing pixelation and improving the overall appearance of fonts,
          this property elevates the visual quality of your website, providing a
          more professional and polished look.c. Consistency Across Browsers:
          While -webkit-font-smoothing is specific to WebKit-based browsers, it
          is widely supported and can be utilized as part of a comprehensive
          font-rendering strategy, ensuring consistent typography across
          different platforms.
        </Text>
        <Text tag={htmlTag.h2}>
          Implementation Of -Webkit-Font-Smoothing: Antialiased:
        </Text>
        <Text>
          To apply -webkit-font-smoothing: antialiased to your website’s
          typography, you need to add the following CSS rule to the desired
          selector or class:
        </Text>
        <code>
          selector {'{'} <br />
          &nbsp;&nbsp;&nbsp;-webkit-font-smoothing: antialiased; <br />
          {'}'}
        </code>
        <Text>
          Remember to include vendor prefixes for other browsers that support
          this property, such as “moz” for Mozilla Firefox.
        </Text>
        <Text tag={htmlTag.h2}>Considerations And Browser Compatibility:</Text>
        <Text>
          While -webkit-font-smoothing can greatly enhance the visual rendering
          of fonts on WebKit browsers, it’s important to note that it may not
          have the same effect on other browsers. Therefore, it’s advisable to
          use this property as part of a broader approach that includes other
          font-smoothing techniques for cross-browser compatibility.
        </Text>
        <Text tag={htmlTag.h2}>Conclusion:</Text>
        <Text>
          In the pursuit of delivering a seamless user experience, paying
          attention to typography is essential. The -webkit-font-smoothing:
          antialiased property offers a simple yet effective way to enhance the
          legibility and aesthetics of your website’s text on WebKit-based
          browsers. By incorporating this technique into your font-rendering
          strategy, you can elevate the overall visual quality of your website’s
          typography, creating a more engaging and readable experience for your
          visitors.
        </Text>
      </>
    ),
    seoProperties: {
      description:
        'In the world of web design, typography plays a crucial role in delivering a visually appealing and readable user experience. While choosing the right font and size is important, there are additional techniques and properties that can further enhance the clarity and smoothness of text rendering on web pages.',
      keywords: ''
    }
  },
  [blogNames.The7CSSMistakesWebDevelopersShouldAvoid]: {
    title: 'The 7 CSS Mistakes Web Developers Should Avoid',
    description:
      'Cascading Style Sheet (CSS) is one of the foundations of the...',
    date: 'August 6, 2022',
    link: '/css-mistakes-web-developers-should-avoid',
    image: blogImages.The7CSSMistakesWebDevelopersShouldAvoid,
    detail: (
      <>
        <Text>
          Cascading Style Sheet (CSS) is one of the foundations of the web page.
          It gives a sense of design and style to the HTML page. There are some
          common mistakes that web developers should avoid while writing CSS:
        </Text>
        <Text tag={htmlTag.h2}>1- Use Color Names Instead Of Hexadecimal</Text>
        <Text>
          Most developers write like the color: orange; in this format, you are
          telling the browser to use any shade of orange, and by giving a color
          name instead of hexadecimal you are also giving the browser control
          over the color, and as a developer, you should never do this. You
          should always write the hexadecimal value of color like{' '}
          <em>color: #F26C4F </em>
          in this format, all browsers will show exactly the same shade which
          you want to show on your webpage.
        </Text>
        <Text>
          Note: You can always find hexadecimal values by inspecting on browser
          and you can also add browser extensions like “ColorZilla for Google
          Chrome”.
        </Text>
        <Text tag={htmlTag.h2}>2- Use PX Instead Of Relative Units</Text>
        <Text>
          Sometimes it is necessary to use absolute PX values, but whenever it
          is possible to use measurement values try to use em(%) and rem
          (root-em). It helps you to ensure that your website scaling is
          according to the user choice of zoom level and device size.
        </Text>
        <Text>So if you have CSS like:</Text>
        <code>
          p {'{'} <br />
          &nbsp;&nbsp;&nbsp;font-size: 18px; <br />{' '}
          &nbsp;&nbsp;&nbsp;line-height: 24px; <br />
          &nbsp;&nbsp;&nbsp;margin-top: 30px;
          <br />
          {'}'}
        </code>
        <Text>You can write:</Text>
        <code>
          html {'{'} <br />
          &nbsp;&nbsp;&nbsp;font-size: 18px; <br />
          {'}'} <br /> p {'{'} <br />
          &nbsp;&nbsp;&nbsp;font-size: 1rem; <br />
          &nbsp;&nbsp;&nbsp;line-height: 1.334em; <br />
          &nbsp;&nbsp;&nbsp;margin-top: 1.667rem; <br />
          {'}'}
        </code>
        <Text tag={htmlTag.h2}>3- Ignore Fall-Back Fonts</Text>
        <Text>
          Not all browsers support all fonts, you must think about this if the
          browser doesn’t support the font which you are using then which font
          it should display. So instead of writing{' '}
          <em>font-family: Helvetica;</em> you can write{' '}
          <em>font-family: Helvetica, Arial, sans-serif;</em> If the browser
          will not support <em>Helvetica</em> it will fall back to{' '}
          <em>Arial</em>
        </Text>
        <Text tag={htmlTag.h2}>4- Don’t Use CSS Shorthands</Text>
        <Text>Using shorthand CSS means, instead of writing:</Text>
        <code>
          font-family: Helvetica; <br />
          font-size: 14px; <br />
          font-weight: bold; <br />
          line-height: 1.5;
        </code>
        <Text>You can write:</Text>
        <code>font: bold 14px/1.5 Helvetica;</code>
        <Text>
          But why we are doing this? Because it will help you to reduce your
          file size, now you can see your four lines of code are now only 1
          line. It will also help you to write organized CSS.
        </Text>
        <Text tag={htmlTag.h2}>5- Use IDs Instead Of Classes</Text>
        <Text>
          As we know the ID of elements is unique and it can be applied on only
          one HTML element through the webpage and classes can be applied on
          more than 1 element, this is one of the main reasons you should use
          classes because CSS will become reusable. To maintain consistency of
          style use only classes in CSS and ID in JS to call specific elements.
        </Text>
        <Text tag={htmlTag.h2}>6- Repeat CSS</Text>
        <Text>
          Repetition of code is not a good practice. Look at this code:
        </Text>
        <code>
          p {'{'} <br />
          &nbsp;&nbsp;&nbsp;font-size: 1rem; <br />
          &nbsp;&nbsp;&nbsp;line-height: 1.334em; <br />
          &nbsp;&nbsp;&nbsp;margin-top: 1.667rem; <br />
          {'}'} <br />
          h6 {'{'} <br />
          &nbsp;&nbsp;&nbsp;font-size: 1rem; <br />
          &nbsp;&nbsp;&nbsp;line-height: 1.334em; <br />
          &nbsp;&nbsp;&nbsp;margin-top: 1.667rem;
          <br />
          {'}'}
        </code>
        <Text>You can write this like:</Text>
        <code>
          p, h6 {'{'} <br />
          &nbsp;&nbsp;&nbsp;font-size: 1rem; <br />
          &nbsp;&nbsp;&nbsp;line-height: 1.334em; <br />
          &nbsp;&nbsp;&nbsp;margin-top: 1.667rem;
          <br />
          {'}'}
        </code>
        <Text>
          It reduces the line of code and improves page load speed. So make sure
          that your CSS is not repeated.
        </Text>

        <Text tag={htmlTag.h2}>7- Unorganized CSS</Text>
        <Text>
          It’s a good practice to do your code neat and well-organized. CSS also
          needs to be well organized. How you can write well-organized CSS? Here
          are some tips that may help:
        </Text>
        <ol>
          <li>
            <Text>Write comments on your CSS</Text>
          </li>
          <li>
            <Text>
              Create logical sections like set up rules for the body,
              paragraphs, headings, lists, tables, and links that can be put
              into general styles or library maybe. After this, you can put all
              the form fields including inputs and buttons. And then you can
              start organizing pages.
            </Text>
          </li>
          <li>
            <Text>Avoid overly inherited selectors.</Text>
          </li>
          <li>
            <Text>Define Variables.</Text>
          </li>
        </ol>
        <Text>Compiling component stylesheets like SCSS.</Text>
      </>
    ),
    seoProperties: {
      description:
        'Cascading Style Sheet (CSS) is one of the foundations of the web page. It gives a sense of design and style to the HTML page.',
      keywords: ''
    }
  },
  [blogNames.HowToBecomeAFrontEndDeveloper]: {
    title: 'How to become a front-end developer?',
    description:
      'A front-end developer creates websites and web applications...',
    date: 'July 6, 2022',
    link: '/how-to-become-a-front-end-developer',
    image: blogImages.HowToBecomeAFrontEndDeveloper,
    detail: (
      <>
        <Text>
          How to become a front-end developer? A front-end developer creates
          websites and web applications, the{' '}
          <Link to={pageLinks.home}>front-end developer</Link> is responsible
          for the things a user can see. It’s one of the popular and
          highest-paid professions. Anyone can be a front-end developer, there
          is no need for a degree or certificate to work. The thing necessary is
          an understanding of front-end development fundamentals and frameworks.
        </Text>
        <Text>
          In this article, I am going to share how you can become a front-end
          developer and which skills you needed to be a front-end developer. The
          purpose of this article is to guide beginners and provide them a line
          to follow and learn skills of front-end development.
        </Text>
        <Text tag={htmlTag.h2}>What Is Front-End?</Text>
        <Text>
          Every website and web application has 2 aspects: front-end and
          back-end. The front-end is what users can see and interact with such
          as clicking on buttons, filling out forms, scrolling, and so on. The
          back-end is events occurring behind the web page which is not visible
          to the user such as database connection, form submission, and so on.
        </Text>
        <Text tag={htmlTag.h2}>
          What Is The Difference Between Front-End And Back-End Development?
        </Text>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <Text size={componentSizes.regular}>Front-end Development</Text>
              </th>
              <th>
                <Text size={componentSizes.regular}>Back-end Development</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Rendering Method</Text>
              </th>
              <td>
                <Text>Browser-side </Text>
              </td>
              <td>
                <Text>Server-side</Text>
              </td>
            </tr>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Typical Stack </Text>
              </th>
              <td>
                <Text>MEAN (MongoDB, Express.js, AngularJS, Node.js) </Text>
              </td>
              <td>
                <Text>LAMP (Linux, Apache, MySQL, PHP/Perl/Python)</Text>
              </td>
            </tr>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Languages/Frameworks </Text>
              </th>
              <td>
                <Text>HTML, CSS, JavaScript, Angular, React </Text>
              </td>
              <td>
                <Text>Java, C, Python, Ruby on Rails, PHP</Text>
              </td>
            </tr>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Priorities </Text>
              </th>
              <td>
                <Text>
                  User experience, Content layout, Image rendering, End-device
                  compatibility, Interface responsiveness{' '}
                </Text>
              </td>
              <td>
                <Text>
                  Data integrity, Server responsiveness, API security,
                  Integration management, Service orchestration
                </Text>
              </td>
            </tr>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Tracked Metrics </Text>
              </th>
              <td>
                <Text>
                  Page load times, Graphic rendering times, Crash rates, Session
                  durations, User ratings/feedback{' '}
                </Text>
              </td>
              <td>
                <Text>
                  Server response times, HTTP call failure rates, Query/Response
                  time, Load Capacity
                </Text>
              </td>
            </tr>
            <tr>
              <th>
                <Text size={componentSizes.regular}>Job Titles </Text>
              </th>
              <td>
                <Text>
                  Front-end Developer, Web Developer, UI/UX Developer{' '}
                </Text>
              </td>
              <td>
                <Text>Back-end Developer, Systems Programmer</Text>
              </td>
            </tr>
          </tbody>
        </table>
        <Text tag={htmlTag.h2}>
          Which Skills Are Required To Be A Front-End Developer?
        </Text>
        <Text>
          Whenever you look at any web page, there are three things you look at
          the web page, HTML, CSS, and JavaScript. These three things need to
          learn and are the foundation of front-end development.
        </Text>
        <Text>
          To become a <Link to={pageLinks.home}>front-end developer</Link>, you
          need expertise in these three and a lot of practice.
        </Text>

        <ol>
          <li>
            <Text>
              <strong>HTML:</strong> Learn how to create a structure with HTML.
            </Text>
          </li>
          <li>
            <Text>
              <strong>CSS:</strong> The next step is CSS, to style and set the
              layout of the web page.
            </Text>
          </li>
          <li>
            <Text>
              <strong>JavaScript:</strong> The next is JavaScript to create
              interactive pages for users.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Libraries and Frameworks:</strong> Learn and keep
              practicing CSS and JS libraries and frameworks such as Bootstrap,
              SASS, jQuery, and React.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Tools and Technologies:</strong> After libraries and
              frameworks, you need to learn about different tools like version
              control systems, which will allow you to track your code changes
              and it is best for teamwork. Examples are GitHub, Beanstalk, etc
            </Text>
          </li>
          <li>
            <Text>
              <strong>Start building projects:</strong> After learning these
              things, you need practice and the best thing for doing practice is
              to start building projects. Start with creating a simple static
              website. You can find many websites online, and start copying any
              websites for practice.
            </Text>
          </li>
        </ol>

        <Text tag={htmlTag.h2}>
          What Are The Roles And Responsibilities Of Front-End Developers?
        </Text>
        <Text>
          As we discussed above, the front-end developer is responsible for what
          users can see. Front-end developer needs to implement web design and
          structure, enhance user experience, and make sure the web page is
          responsive on all screen sizes.
        </Text>
      </>
    ),
    seoProperties: {
      description:
        'How to become a front-end developer? A front-end developer creates websites and web applications',
      keywords: ''
    }
  },
  [blogNames.The10MostCommonMistakesWebDevelopersMake]: {
    title: 'The 10 Most Common Mistakes Web Developers Make',
    description: 'There are some common mistakes that web developers...',
    date: 'June 22, 2022',
    link: '/mistakes-web-developers-make',
    image: blogImages.The10MostCommonMistakesWebDevelopersMake,
    detail: (
      <>
        <Text>
          Since WWW (World Wide Web) was coined back in 1990, static HTML pages
          converted into completely dynamic. Today we have thousands of
          resources and tools which are providing step-by-step details of web
          application development.
        </Text>
        <Text>
          Nowadays, development environments are smart enough to catch and
          resolve the issues and bugs that developers battled with on daily
          basis earlier. Now there are many web development platforms but all of
          them have a common ground.
        </Text>
        <Text>
          The purpose of sharing these tips is because there are some common
          mistakes that web developers made at different stages of the
          development process, and these tips can help a{' '}
          <Link to={pageLinks.home}>developer</Link> improve the strategies and
          techniques of development. Here I am going to shed light on some
          topics like SEO, Scalability, Security, Validation, and Usability.
        </Text>
        <Text tag={htmlTag.h2}>1- Incomplete Input Validation</Text>
        <Text>
          Validation of inputs on the client and server sides is necessary. Most
          developers don’t add proper validation on both sides, which affects
          usability and security, and can cause improper data and error.
        </Text>
        <Text>
          Proper validation on inputs will improve user experience, increase the
          security level, improve error prevention, give you great performance
          and make your data clean. Validations are really easy to set up
          because most of the frameworks of frontend development and platform of
          backend development provide built-in validation rules.
        </Text>
        <Text>
          Validations might be time-consuming to implement on inputs, but they
          should be a part of best coding practices for every developer.
        </Text>
        <Text tag={htmlTag.h2}>2- Ignore Label Tags With Input</Text>

        {/* TODO: Add link in text */}
        <Text>
          Sometimes there is a design on which there is no label only input with
          a placeholder. In this case frontend developers should add a hidden
          label, the main reason is if we talk about applications for
          vision-impaired people, these types of apps always try to find{' '}
          {'<label>'} and if there will be no label tag then input cannot be
          readable for these type of apps, it will affect the usability of your
          web page.
        </Text>
        <Text tag={htmlTag.h2}>
          3- Authentication Without Proper Authorization
        </Text>
        <Text>
          What is Authentication? It’s verification of a user’s security
          credentials i.e. password, the answer to security questions,
          fingerprint, etc.
        </Text>
        <Text>
          What is Authorization? It’s confirmation of permission and access of a
          particular user.
        </Text>
        <Text>
          In other words, authentication is “Who is Entity” and authorization is
          “What a given entity can do”. Both of them are required to secure
          data. These two provide great security and protect against brute force
          hacking. Without proper authorization, you risk revealing your
          sensitive information via a breach or unauthorized access and
          resulting in bad press, customer loss, and potential regulatory fines.
        </Text>
        <Text tag={htmlTag.h2}>4- Wrong Or Incomplete SEO</Text>
        <Text>
          Many web developers think that they know about SEO and they can handle
          it as it is not much complex, but that is not true. SEO Specialist is
          required to handle SEO of web pages. As their study requires time to
          research about best practices and ever-changing algorithms of Google,
          Bing, and Yahoo. Doing experiments, tracking and analysis does not
          mean you are an SEO specialist.
        </Text>
        <Text>
          SEO is not the only adjustment of good content, tags, keywords, meta
          tags, alt attributes on images, site maps, etc. There is a need to get
          rid of duplicate content, have crawlable site architecture, efficient
          load times, intelligent back linking and internal linking, etc.
        </Text>
        <Text>
          SEO of web pages should be scalable and you should think about it the
          moment you start developing your web application.
        </Text>
        <Text tag={htmlTag.h2}>5- Ignore Bandwidth Optimization</Text>
        <Text>
          Optimizing usage of bandwidth provides great performance, to gain this
          you need to do a couple of things, some of them during development and
          some after development. Most the experienced web developers use these
          tricks:
        </Text>
        <ol>
          <li>
            <Text>Minify and Combine HTML, CSS, and JavaScript Files</Text>
          </li>
          <li>
            <Text>Load JavaScript Asynchronously</Text>
          </li>
          <li>
            <Text>
              Consider Using Prefetch, Preconnect, and Prerender Techniques
            </Text>
          </li>
          <li>
            <Text>Server-side HTTP Compression</Text>
          </li>
          <li>
            <Text>Optimize images (It includes size and resolution)</Text>
          </li>
        </ol>
        <Text>
          As a good web developer, you need to keep these things as best
          practices. It should be on your checklist of development.
        </Text>
        <Text tag={htmlTag.h2}>6- Cross-Browser Incompatibility</Text>
        <Text>
          This is the ability of websites, web applications, or scripts to
          support different web browsers in the same manner. This is more
          related to the webpage’s functionality than its look, which means all
          the scripts must perform identically in various web browser
          environments. With the help of a few techniques, you can make sure
          that your site is cross-browser compatible:
        </Text>
        <ol>
          <li>
            <Text>Simple Coding</Text>
          </li>
          <li>
            <Text>Add Validations</Text>
          </li>
          <li>
            <Text>Setup Doctype</Text>
          </li>
          <li>
            <Text>Keep CSS Reset Rules in mind</Text>
          </li>
          <li>
            <Text>Conditional Comments</Text>
          </li>
          <li>
            <Text>Include as many browsers in your testing</Text>
          </li>
        </ol>
        <Text tag={htmlTag.h2}>7- Writing Old School HTML</Text>
        <Text>
          HTML evolved over the past few years, for example, earlier developers
          used {'<table>'} for layout. Decade’s HTML tags and techniques can
          affect cross-browser compatibility. Make sure to use HTML5 tags on
          your web page to provide a better structure. It will also help you in
          terms of SEO.
        </Text>
        <Text tag={htmlTag.h2}>8- Ignore Warning Messages</Text>
        <Text>
          Most developers ignore warning messages, later it might be converted
          into errors. Your web page should be warning-free, a good developer
          needs to keep this as the best practice.
        </Text>
        <Text tag={htmlTag.h2}>9- Writing Code Without Planning</Text>
        <Text>
          To write high-quality and well-structured code, you need proper
          planning and research. With proper planning, your code will be neat
          and clean. If you directly start your code without thinking and
          planning, it will work for small projects, but for large projects, it
          could be a mess.
        </Text>
        <Text tag={htmlTag.h2}>
          10- Not Developing For Different Screen Sizes
        </Text>
        <Text>
          The growth of smartphones with different screen sizes has brought many
          new ways to access online content, but it also brought web development
          issues. The number of website visits from smartphones and tablets is
          growing every day, that is why web developer needs to make sure that
          the webpage can be accessed properly on each screen resolution.
        </Text>
        <Text>
          There are many techniques and strategies to build responsive web
          pages. Different developers have their own tricks to make websites
          responsive.
        </Text>
        <Text>
          Bootstrap is very commonly used for responsive web pages. By adding
          their pattern and techniques to your best practice, you can easily
          avoid responsive issues on web pages.
        </Text>
      </>
    ),
    seoProperties: {
      description:
        'Since WWW (World Wide Web) was coined back in 1990, static HTML pages converted into completely dynamic. ',
      keywords: ''
    }
  },
  [blogNames.HTMLMistakesWebDevelopersShouldAvoid]: {
    title: 'The 8 HTML Mistakes Web Developers Should Avoid',
    description:
      'Most developers from beginners to advance made some mistakes...',
    date: 'June 29, 2022',
    link: '/html-mistakes-web-developers-should-avoid',
    image: blogImages.HTMLMistakesWebDevelopersShouldAvoid,
    detail: (
      <>
        <Text>
          Most developers from beginners to advance made some mistakes while
          writing HTML code. In this article, I am going to share some HTML
          mistakes web developers should avoid.
        </Text>
        <Text tag={htmlTag.h2}>1- Missing Doctype</Text>
        <Text>
          The W3C has set up some standards for the web, all browser vendors and
          web developers need to stick with these standards to ensure that the
          website is rendering perfectly and identically through all the
          browsers. The DOCTYPE declaration is one of these standards.
        </Text>
        <Text>
          DOCTYPE is the very first line of an HTML document. It shows a version
          of the page’s HTML and ensures that the web page is rendering
          identically across different web browsers. It means this doctype
          declaration is necessary and it must be at the top of the page.
        </Text>
        <code>
          <b>Bad Practice:</b>
          <br />
          <br />
          {'<html>'} <br />
          &nbsp;&nbsp;&nbsp;{'<head>'} <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<title>Page Title</title>'}
          <br />
          &nbsp;&nbsp;&nbsp;{'</head>'}
          <br />
          &nbsp;&nbsp;&nbsp;{'<body>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page Content &nbsp;&nbsp;&nbsp;
          <br />
          &nbsp;&nbsp;&nbsp;{'</body>'}
          <br />
          {'</html>'}
        </code>
        <code>
          <b>Good Practice:</b>
          <br />
          <br />
          {'<!DOCTYPE html>'} <br />
          {'<html>'} <br />
          &nbsp;&nbsp;&nbsp;{'<head>'} <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<title>Page Title</title>'}
          <br />
          &nbsp;&nbsp;&nbsp;{'</head>'}
          <br />
          &nbsp;&nbsp;&nbsp;{'<body>'}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page Content &nbsp;&nbsp;&nbsp;
          <br />
          &nbsp;&nbsp;&nbsp;{'</body>'}
          <br />
          {'</html>'}
        </code>
        <Text>
          Tip: Declaration of doctype is not case sensitive. You can write like:
        </Text>
        <code>
          {'<!DOCTYPE html>'}
          <br />
          {'<!DocType html>'}
          <br />
          {'<!Doctype html>'}
          <br />
          {'<!doctype html>'}
          <br />
        </code>
        <Text tag={htmlTag.h2}>2- Inline Style In HTML Tags</Text>
        <Text>
          Inline CSS may have a purpose but it’s not a good way to maintain it.
          There are many issues with inline CSS like you cannot see quotations
          within inline CSS, cannot be reused, tough to be edited, etc. It also
          doesn’t provide browser cache advantages.
        </Text>
        <Text>
          Inline CSS may be faster than external CSS but still writing an
          external stylesheet is the best practice.
        </Text>
        <Text tag={htmlTag.h2}>3- Using Multiple Line Breaks</Text>
        <Text>
          There is a need to add some gap between content, but writing {'<br>'}{' '}
          multiple times is outdated. Instead of writing it multiple times, you
          can split your content into multiple tags and can add margin or
          padding for gaps.
        </Text>
        <code>
          <b>Bad Practice:</b>
          <br />
          <br />
          {'<p>'}
          <br />
          &nbsp;&nbsp;&nbsp;This is a sentence.
          <br />
          &nbsp;&nbsp;&nbsp;{'<br>'}
          <br />
          &nbsp;&nbsp;&nbsp;{'<br>'}
          <br />
          &nbsp;&nbsp;&nbsp;This is a sentence.
          <br />
          {'</p>'}
        </code>
        <code>
          <b>Good Practice:</b>
          <br />
          <br />
          {'<p>This is a sentence.</p>'}
          <br />
          {'<p>This is a sentence.</p>'}
        </code>
        <Text tag={htmlTag.h2}>
          4- Use {'<B>'} And {'<I>'}
        </Text>
        <Text>
          {'<b>'} and {'<i>'} are used for bold and italic, these are still
          available in HTML and you will not find any error with them but they
          are not recommended now. With the updates on HTML version, {'<b>'} is
          replaced with {'<strong>'} and {'<i>'} is replaced with {'<em>'}.
          Instead of using old tags, upgrade yourself to use recommended one.
        </Text>
        <Text tag={htmlTag.h2}>5- Block Elements Within Inline Elements</Text>
        <Text>
          All HTML elements has default display setting, block or inline. Block
          elements are {'<p>'}, {'<div>'} etc use for structure of HTML web
          page. Inline elements like {'<a>'}, {'<span>'} etc live in these block
          elements. You should never put block elements inside inline elements.
        </Text>
        <code>
          <b>Bad Practice:</b>
          <br />
          <br />
          {'<a href=”#”><h2>This is a sentence.</h2></a>'}
        </code>
        <code>
          <b>Good Practice:</b>
          <br />
          <br />
          {'<h2><a href=”#”>This is a sentence. </a></h2>'}
        </code>
        <Text tag={htmlTag.h2}>6- Ignore Alt Attribute For Image Tag</Text>
        <Text>
          Alt attribute is required for {'<img>'}. It shows the context of the
          image. Showing users a blank area is not a good user experience,
          instead of showing the blank area you should write the alt attribute
          in the img tag so users can expect what is going to be loaded here.
        </Text>
        <Text tag={htmlTag.h2}>7- Don’t Write Comments</Text>
        <Text>
          Documented code is better for future changes in code. Every developer
          should keep this in practice to write comments.
        </Text>
        <Text tag={htmlTag.h2}>8- Forget To Encode HTML Entities</Text>
        <Text>
          Most developers forget to encode HTML entities like @, ©, etc. The
          developer should encode it to the numbers because browsers may not
          support entity names but they support entity numbers. For example, to
          display a less than sign ({'<'}) you must write {'&lt;'} or {'&#60;'}.
        </Text>
      </>
    ),
    seoProperties: {
      description:
        'Most developers from beginners to advance made some mistakes while writing HTML code. In this article, I am going to share some HTML mistakes web developers should avoid.',
      keywords: ''
    }
  },
  [blogNames.HowToCreateAProposalThatWinsJobs]: {
    title: 'How to create a proposal that wins jobs?',
    description:
      'Proposal writing is a skill itself and keeps practicing can improve...',
    date: 'June 15, 2022',
    link: '/how-to-create-a-proposal-that-wins-jobs',
    image: blogImages.HowToCreateAProposalThatWinsJobs,
    detail: (
      <>
        <Text>
          Proposal writing is one of the other strategies to win clients.
          Proposal writing is a skill itself and keeps practicing can improve
          your technique of writing proposals. A few common elements of good
          proposals are:
        </Text>
        <ol>
          <li>
            <Text>
              <b>Greetings and introduction:</b> It should be quick and
              straightforward.
            </Text>
          </li>
          <li>
            <Text>
              <b>Clear Statement:</b> Tell clients that you can start right now
              and can solve their problems.
            </Text>
          </li>
          <li>
            <Text>
              <b>Why you?</b> Tell clients why you are a good fit for the
              project.
            </Text>
          </li>
          <li>
            <Text>
              <b>Portfolio:</b> Add you’re relevant portfolio.
            </Text>
          </li>
        </ol>
        <Text>
          Here are some more techniques and strategies you need to keep in mind
          while writing proposals:
        </Text>
        <Text tag={htmlTag.h2}>Well-Formatted</Text>
        <Text>
          Most freelancers did mistakes like they write proposals all in capital
          letters because they think clients will pay more attention to
          all-caps, but believe me, it’s not! Neat and well-formatted proposals
          are more attractive to clients.
        </Text>
        <Text tag={htmlTag.h2}>Short Proposals</Text>
        <Text>
          Try to minimize your words and provide all details in summarized form.
          Sometimes clients don’t like lengthy proposals. This usually depends
          on job type, sometimes clients want to hire for a full-time position
          and they want freelancers to provide details about themselves, in this
          case, you can write long proposals about yourself but only write
          meaningful and job-related information.
        </Text>
        <Text tag={htmlTag.h2}>Portfolio Management</Text>
        <Text>
          Don’t attach your portfolio as an image or document, try to provide
          live links to clients, and in short, make it easy for clients.
          Sometimes clients don’t check your portfolio if your file is heavy or
          take time in downloading. The best thing is if you want to share
          documents you can use Google docs or Google sheets or if you want to
          show images to your client you can use free tools to host your images
          like imgbb.com.
        </Text>
        <Text tag={htmlTag.h2}>Provide Necessary Information</Text>
        <Text>
          Mostly freelancers write more about themselves instead of projects.
          The best thing is just to add your years of experience and your skills
          in 2 lines and then start talking about client projects and after that
          show your portfolio. Keep your proposal summarized and full of pieces
          of information.
        </Text>
        <Text tag={htmlTag.h2}>Learn About The Client First</Text>
        <Text>
          Learn about project scope and client requirements first, then write a
          proposal. Your proposal must be about your clients, their problems,
          and the ways that you can solve them.
        </Text>
        <Text tag={htmlTag.h2}>Focus On The Job Description</Text>
        <Text>
          Most freelancers did not pay attention to job details, and try to
          submit their proposals ASAP. I know there is a lot of competition but
          still, you need to pay attention to the job description and then write
          a proposal according to the job. Your proposal should reflect that you
          have read the job description properly.
        </Text>
        <Text tag={htmlTag.h2}>Copy-Paste Proposals Don’t Work</Text>
        <Text>
          Most freelancers create templates and use the same template every
          time, but you need to change it according to the job description.
        </Text>
        <Text tag={htmlTag.h2}>Give Suggestions And Add Questions</Text>
        <Text>
          Giving suggestions regarding a job shows the seriousness of a
          freelancer, if the client likes your suggestion your hiring chances
          can be increased. And if you ask questions, it will show that you have
          paid proper attention to the job description.
        </Text>
        <Text tag={htmlTag.h2}>Job Winning Proposal Template</Text>
        <Text>
          As I mentioned above, we shouldn’t use the same template for every job
          post, but for beginners, I am adding my proposal template, I update it
          according to the job post but this pattern is short and full of pieces
          of informations.
        </Text>
        <code>
          Hey! My name is <i>[Your Name]</i>, and I have 4+ years of experience
          as a <i>[Your Title]</i>. I have read your job post and looks like I
          am a perfect fit for the position because{' '}
          <i>
            [add your skills here as well as the reason why the client choose
            you]
          </i>
          .
          <br />
          <br />
          You can look into my portfolios:
          <br />
          <i>[Porfolio link 1]</i>
          <br />
          <i>[Porfolio link 2]</i>
          <br />
          <i>[Porfolio link 3]</i>
          <br />
          <i>[Porfolio link 4]</i>
          <br />
          <i>[Porfolio link 5]</i>
          <br />
          <br />
          <i>[answer your client's questions here]</i>
          <br />
          <br />
          <i>
            [If the client asks you to mention your charges on the cover letter
            you can mention here]
          </i>
          <br />
          <br />
          If you are interested to hire me please let me know your availability,
          I will make sure to make myself available to discuss this in detail.
          <br />
          <br />
          Thank you!
        </code>
      </>
    ),
    seoProperties: {
      description:
        'Proposal writing is one of the other strategies to win clients. Proposal writing is a skill itself and keeps practicing can improve your technique of writing proposals.',
      keywords: ''
    }
  },
  [blogNames.HowDoGetMoreLongTermClientsTipsForFreelancers]: {
    title: 'How do get more long-term clients? Freelancing Tips for beginners.',
    description:
      'There are many strategies to get more clients. Here, I am sharing some tips...',
    date: 'June 8, 2022',
    link: '/tips-for-new-freelancers',
    image: blogImages.HowDoGetMoreLongTermClientsTipsForFreelancers,
    detail: (
      <>
        <Text>
          If you want to know how to get more clients, you are not alone. There
          are a lot of agencies and many freelancers who are constantly looking
          for other ways to get new clients and increase their revenue.
        </Text>
        <Text>
          There are many strategies to get more clients and some tips for new
          freelancers. Here, I am sharing some tips which have been very useful
          for me and work for me every time.
        </Text>
        <Text tag={htmlTag.h2}>Improve Your Communication Skills</Text>
        <Text>
          “Communication is a key.” As we all know communication is a very
          important part to build any relationship, here we are also creating
          relationships with clients so we need good communication skills.
        </Text>
        <Text>
          Poor communication can also affect the trust and belief of clients.
          There are different ways to improve communication skills, some of them
          are:
        </Text>
        <ul>
          <li>
            <Text>Practice active listening</Text>
          </li>
          <li>
            <Text>Focus on non-verbal communication</Text>
          </li>
          <li>
            <Text>Ask for feedback</Text>
          </li>
          <li>
            <Text>Manage your emotions</Text>
          </li>
          <li>
            <Text>Practice public speaking</Text>
          </li>
        </ul>
        <Text>
          It’s something clients like about me, you can read reviews on the home
          page. That is why I recommend improving your communication skills
          first.
        </Text>
        <Text tag={htmlTag.h2}>
          Adjust Your Availability According To The Clients
        </Text>
        <Text>
          Address customer queries faster. Make yourself available according to
          the time zone of the client. Most customers like a quick response.
        </Text>
        <Text>
          There are already a lot of freelancers who are dealing with customers
          on the same job post. If your response rate will good your chances of
          getting hired will increase.
        </Text>
        <Text tag={htmlTag.h2}>Understanding Your Customer</Text>
        <Text>
          What does your client actually want? You need to understand your
          client’s requirements, and before accepting the offer clear all the
          work and deliverables with the client.
        </Text>
        <Text tag={htmlTag.h2}>Always Offer Free Consultation</Text>
        <Text>
          Always provide a free consultation to your client, and help them to
          understand better. Not all clients have technical knowledge, some of
          them are non-technical so help them to understand what they are
          looking for.
        </Text>
        <Text tag={htmlTag.h2}>Engage Clients On The Entire Journey</Text>
        <Text>
          Try to give updates on a daily or weekly basis depending on project
          length. And keep asking for suggestions and approval from the client.
        </Text>
        <Text tag={htmlTag.h2}>Well Written Proposals</Text>
        <Text>
          Mostly freelancers write more about themselves instead of projects.
          The best thing is just to add your years of experience and your skills
          in 2 lines and then start talking about the client’s project and after
          that show your portfolio. Keep your proposal summarized and full of
          pieces of information.
        </Text>
        <Text tag={htmlTag.h2}>Improve Your Writing Skills</Text>
        <Text>
          There are a lot of online videos and courses to improve your writing.
          This is one of the major parts, which shows your seriousness with your
          work. To communicate better, you need to improve your writing skills.
        </Text>
        <Text tag={htmlTag.h2}>Add Live Portfolios</Text>
        <Text>
          Don’t attach your portfolio as an image or document, try to provide
          live links to clients, and in short, make it easy for clients.
          Sometimes clients don’t check your portfolio if your file is heavy or
          take time in downloading. The best thing is if you want to share
          documents you can use Google docs or Google sheets or if you want to
          show images to your client you can use free tools to host your images
          like imgbb.com.
        </Text>
        <Text tag={htmlTag.h2}>Good Profile Picture</Text>
        <Text>
          Avoid using avatars, logos, drawings, illustrations, fake stock
          photos, or selfies. Capture your decent picture and use it as your
          profile photo. The reason is your clients will often judge your
          personality by your looks, so always show your decent and smiling
          personality.
        </Text>
        <Text tag={htmlTag.h2}>Active On Social Media</Text>
        <Text>
          Many agencies and freelancers start getting clients by posting
          testimonials about their work. Post testimonials on your social media
          profiles like Facebook, Twitter, and LinkedIn.
        </Text>
        <Text tag={htmlTag.h2}>Be Honest – Built Trust With Clients</Text>
        <Text>
          First, communicate properly, and always be honest with clients, some
          freelancers try to charge extra with non-technical clients and try to
          fool them, which is not good.
        </Text>
        <Text>
          Personalize your services as the client wants, and always make them
          happy and satisfied.
        </Text>
        <Text>
          Convey your customers about your business objectives and ask them to
          share their opinions. This will make them feel valued and part of your
          business.
        </Text>
        <Text>Meet and exceed customer expectations!</Text>
        <Text tag={htmlTag.h2}>Build Your Website</Text>
        <Text>
          First and foremost, make sure your website is about your clients, and
          the value they will get from you. Highlight your success stories. You
          can also upload case studies, to further reinforce the claims of your
          industrial expertise.
        </Text>
        <Text tag={htmlTag.h2}>Be Patient</Text>
        <Text>
          Be patient, and keep trying. I got my first project after 6 months. So
          never give up!
        </Text>
      </>
    ),
    seoProperties: {
      description:
        'If you want to know how to get more clients, you are not alone. There are a lot of agencies and many freelancers who are constantly looking for other ways to get new clients and increase their revenue.',
      keywords: ''
    }
  }
  //   [blogNames.EnhancingTypographyWithWebkitFontSmoothingAntialiased]:{
  //     title: '',
  //     description: '',
  //     date: '',
  //     link: '',
  //     image: blogImages.EnhancingTypographyWithWebkitFontSmoothingAntialiased
  // detail: <Text>j</Text>,
  // seoProperties: {description: '', keywords: ''}
  //   }
};
