import React from 'react';
import { Layout } from 'src/Layout';
import { Blog, icons, pageLinks, person } from 'src/config';
import { componentSizes, htmlTag } from 'src/types';
import { HeavyText, Icon, Image, Link, Text } from 'src/widget';
import { Section } from '../Section';
import classes from './Post.module.scss';

const UnmemoPost: React.FC<Blog> = ({
  title,
  detail,
  date,
  image,
  seoProperties
}) => {
  return (
    <Layout
      title={`${title} - ${person.name}`}
      description={seoProperties.description}
      keywords={seoProperties.keywords}
    >
      <Section>
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <HeavyText
                tag={htmlTag.h1}
                size={'small'}
                className={classes.title}
              >
                {title}
              </HeavyText>

              <div className={classes.details}>
                <div className={classes.row}>
                  <Icon src={icons.profile} />
                  <Link to={pageLinks.home}>
                    <Text size={componentSizes.regular}>Isha Hasan</Text>
                  </Link>
                </div>
                <div className={classes.row}>
                  <Icon src={icons.date} />
                  <Text size={componentSizes.regular}>{date}</Text>
                </div>
              </div>

              <div className={classes.thumbnail}>
                <Image src={image} alt={title} />
              </div>

              <div className={classes.content}>{detail}</div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export const Post = React.memo(UnmemoPost);
