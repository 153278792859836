import React from 'react';
import classes from './GradientCircle.module.scss';

type Props = {
  size?: 'one-line' | 'two-line';
};

const UnmemoGradientCircle: React.FC<Props> = ({ size = 'one-line' }) => {
  return (
    <div
      className={`${classes.circle} ${
        size === 'one-line' ? classes.small : ''
      }`}
    >
      <div className={classes.color1}></div>
      <div className={classes.color2}></div>
    </div>
  );
};

export const GradientCircle = React.memo(UnmemoGradientCircle);
