import {
  ClientFeedback,
  Highlight,
  Services,
  Technologies,
  client
} from 'src/types';
import { clientLogos, icons, images } from './images';

export const highlights: Highlight[] = [
  {
    value: '5+',
    title: 'Years of experience',
    description: 'Years developing websites with diverse technologies.'
  },
  {
    value: '$35',
    title: 'Hourly rate',
    description: 'Competitive hourly rate for quality web development.'
  },
  {
    value: '100%',
    title: 'Success rate',
    description: 'Success rate delivering quality projects.'
  },
  {
    value: '100+',
    title: 'Completed projects',
    description: 'Successfully completed numerous projects.'
  }
];

export const services: Services[] = [
  {
    icon: icons.website,
    title: 'Website Development',
    description:
      'I create beautiful, responsive websites using a variety of technologies, including HTML, CSS, and JavaScript. From simple landing pages to complex e-commerce platforms, I can help bring your vision to life.',
    tags: [
      'CSS',
      'HTML',
      'Flex box',
      'SCSS',
      'SASS',
      'Bootstrap',
      'PHP',
      'WordPress',
      'jQuery',
      'JavaScript',
      'css animation',
      'GitHub',
      'GraphQL',
      'PSD to HTML',
      'GatsbyJS',
      'Front-End Development',
      'Web Development',
      'PhpMyAdmin',
      'Web Design',
      'HTML5',
      'HTML Emails',
      'CSS Grid Layout',
      'Responsive Web Design',
      'PSD to Wordpress'
    ]
  },
  {
    icon: icons.webApp,
    title: 'Web App Development',
    description:
      "Need a custom web application to meet your business needs? I have experience building web apps using ReactJS, NodeJS, and other technologies. Let's discuss how I can help you achieve your goals.",
    tags: [
      'Amazon Web Services (AWS)',
      'Datadog',
      'GitHub',
      'Git',
      'Node.js',
      'headless',
      'Checkout',
      'TypeScript',
      'Next.js',
      'Redux.js',
      'React.js',
      'Data Structures',
      'Bootstrap',
      'Web Design',
      'HTML5',
      'SASS',
      'JavaScript',
      'CSS Flexbox',
      'CSS Grid Layout'
    ]
  },
  {
    icon: icons.html,
    title: 'HTML Emails/Invoices Development',
    description:
      'Looking for a professional and responsive email template or HTML invoice design? I can create custom designs that look great on any device and are easy to use. Contact me to discuss your specific needs.',
    tags: ['CSS', 'HTML', 'MJML', 'Outlook', 'HTML5', 'HTML Emails']
  }
];

export const clientFeedback: ClientFeedback[] = [
  {
    image: images.stacy,
    name: 'Stacy Stubblefield',
    designation: 'Director',
    company: '8th Street Ventures LLC',
    message:
      'Great work! Project was completed very quickly, with high quality and at a great rate. Very easy to communicate with and extremely responsive. Highly recommended!'
  },
  {
    image: images.vincent,
    name: 'Vincent John',
    designation: 'Graphic Designer',
    message:
      "Isha is a really awesome freelance. She managed to rank our website from a very poor score to the highest extent. She is very understanding and willing to help. I asked for her help regarding the menu tab and she's happy to amend it. If you saw him applying on your job post, HIRE HER and you'll never be disappointed. Highly recommended!"
  },
  {
    image: images.amit,
    name: 'Amit Sukhija',
    designation: 'CEO',
    company: 'ZestIot',
    message:
      'Great work done by Isha & team, More than 5 on Professionalism, helping on different issues & showing ownership to finish the project within same budget.'
  },
  {
    image: images.joshua,
    name: 'Joshua Harder',
    designation: 'CEO',
    company: 'Cozy Collection',
    message:
      'Working with her is simply very easy. She is very efficient, attentive, knowledgeable, polite and easy to work with. I have been very thankful, appreciative and extremely satisfied with her work. She has put all his efforts to make it work! I surely will hire her for the next job!'
  },
  {
    image: images.sangeeta,
    name: 'Sangeeta',
    designation: 'Digital Marketing Specialist',
    message:
      'Isha is a great freelancer for web development work. she has great knowledge and works with full responsibility. Isha has created 3-4 websites for me and fixes few issues with my websites. I would highly recommend her.'
  },
  {
    image: images.kastriot,
    name: 'Kastriot Sula',
    designation: 'Marketing Specialist',
    message:
      'Isha delivered good work on this project and her communication was perfect. I enjoyed working with Isha and will likely have additional jobs for her in the future. Thank you!'
  },
  // {
  //   image: images.amit,
  //   name: 'Zoe Olaru',
  //   designation: 'Graphic Designer',
  //   company: '',
  //   message:
  //     'Working with Isha was a great experience and i can highly recommend her if you need help building customised email templates. She crafted the template code from the visual design provided and delivered the results super fast. Thanks!'
  // },
  // {
  //   image: images.amit,
  //   name: 'Omar',
  //   designation: '',
  //   company: '',
  //   message: ''
  // },
  // {
  //   image: images.amit,
  //   name: 'Amer',
  //   designation: '',
  //   company: '',
  //   message: ''
  // },
  // {
  //   image: images.amit,
  //   name: 'Mehmood',
  //   designation: '',
  //   company: '',
  //   message: ''
  // },
  {
    image: images.hamza,
    name: 'Hamza Andi',
    designation: 'CEO',
    company: 'Tender Jordan',
    message:
      "Our company has been working with Isha Hasan. We are beyond pleased with Isha's impeccable service. She is extremely helpful in guiding us through the whole process. She is very knowledgeable. She pays close attention to details. She is also very patient in tracking and developing the web against the changes. Overall, we are very pleased with Isha's deliverables."
  }
  // {
  //   image: images.amit,
  //   name: '',
  //   designation: '',
  //   company: '',
  //   message: ''
  // }
];

export const clients: client[] = [
  { image: clientLogos.playground, name: 'treestone' },
  { image: clientLogos.zestiot, name: 'zestiot' },
  { image: clientLogos.tenderJordan, name: 'tenderJordan' },
  { image: clientLogos.invoiceq, name: 'invoiceq' },
  { image: clientLogos.ewigo, name: 'ewigo' },
  { image: clientLogos.handlangers, name: 'handlangers' },
  { image: clientLogos.antoine, name: 'antoine' },
  { image: clientLogos.eightstreet, name: 'eightstreet' },
  { image: clientLogos.samahi, name: 'samahi' },
  { image: clientLogos.treestone, name: 'treestone' }
];

const technologies: Technologies[] = [
  {
    name: 'Frontend Tools',
    detail: [
      'JavaScript(ES6+)',
      'TypeScript',
      'HTML5',
      'React.js',
      'Next.js',
      'Gatsby.js',
      'Redux',
      'Redux Toolkit',
      'Redux Thunk',
      'Redux Saga',
      'PWA',
      'React Testing Library',
      'Jest',
      'Cypress',
      'Sentry',
      'Node.js/Express',
      'GraphQL',
      'Contentful/Strapi/Prismic CMS',
      'Git/Gitlab/Github/BitBucket'
    ]
  },
  {
    name: 'UI Libraries',
    detail: [
      'CSS3/SCSS',
      'Framer Motion',
      'GSAP',
      'Styled Components',
      'Bootstrap',
      'Material UI',
      'React Storybook'
    ]
  }
];

export const content = {
  home: {
    hero: {
      text1:
        "I am passionate about quality code and best practices. I've created many successful projects.",
      text2:
        "I bring 5+ years of industry expertise and a flawless track record to freelance projects. Let's collaborate today!",
      primaryCTA: 'Let’s talk',
      primaryLink: 'Download Resume'
    },
    whoIAm: {
      title: 'Who I’m?',
      description:
        "I'm a Karachi-based front-end developer with a passion for creating quality websites. Graduated in 2019 with a silver medal for securing 2nd position in my batch, I've honed my skills in HTML, CSS, and JavaScript, and have experience in WordPress, landing page design, and SaaS products. I specialize in HTML, CSS, and JavaScript, website design and development, WordPress, email templates, blogs, and landing pages using ReactJS, Gatsby, TypeScript, and NodeJS.",
      primaryCTA: 'Hire Me',
      primaryLink: 'Learn More',
      highlightPoints: highlights
    },
    whatIDo: {
      title: 'What do I help?',
      description:
        'Take a look at the services I offer for website design and development. From WordPress themes to custom landing pages, I provide a wide range of solutions to help you achieve your goals.',
      services: services
    },
    myClients: {
      title: 'What clients say about me',
      feedback: clientFeedback,
      logo: clients
    }
  },
  about: {
    hero: {
      title: 'About',
      primaryCTA: 'Let’s talk',
      primaryLink: 'Download Resume'
    },
    myDetails: {
      info: [
        "I'm a front-end developer based in Karachi, Pakistan with over 4 years of experience in web development. I have a passion for creating beautiful, responsive websites and web applications.",
        'I graduated in 2019 with a degree in Software Engineering and achieved a silver medal for securing the 2nd position in my batch. Since then, I have been developing a wide range of websites using technologies such as HTML, CSS, JavaScript, jQuery, PHP, AJAX Bootstrap, WordPress, ReactJS, TypeScript, and NodeJS. ',
        "My expertise includes WordPress theme development, landing page design, and custom web applications. I take pride in delivering high-quality work, and I'm always eager to take on new challenges and push my skills to the next level."
      ],
      technologies: technologies
    }
  }
};

export const footer = {
  title: 'Want to Start a Project?',
  button: "Let's Talk",
  copyRight: 'Copyright © 2024 Isha Hasan - All Rights Reserved'
};

export const socialLinks = {
  whatsapp: 'https://wa.link/fvqm53',
  gmail: 'mailto:ishahasan9993@gmail.com',
  linkedIn: 'https://www.linkedin.com/in/isha-hasan-24ba64177',
  skype: 'https://join.skype.com/invite/isWoKGFDaGxl',
  facebook: 'https://www.facebook.com/Ishahasan69'
};
