export const person = {
  name: 'Isha Hasan',
  designation: 'Sr. Software Engineer',
  speciality: 'Frontend Developer',
  email: 'hello@ishahasan.com'
};

export const pageLinks = {
  home: '/',
  about: '/about',
  contact: '/contact',
  blog: '/blog'
};
