export type Highlight = {
  value: string;
  title: string;
  description: string;
};

export type Services = {
  icon: string;
  title: string;
  description: string;
  tags: string[];
};

export type ClientFeedback = {
  image: string;
  name: string;
  designation: string;
  company?: string;
  message: string;
};

export type client = {
  name: string;
  image: string;
};

export type Technologies = {
  name: string;
  detail: string[];
};

export const componentSizes = {
  small: 'small',
  regular: 'regular',
  medium: 'medium',
  large: 'large'
} as const;

export type ComponentSize =
  (typeof componentSizes)[keyof typeof componentSizes];

export const htmlTag = {
  p: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  div: 'div',
  tag: 'tag',
  span: 'span'
} as const;

export type HtmlTag = (typeof htmlTag)[keyof typeof htmlTag];

export type SEOProperties = {
  title: string;
  description: string;
  keywords: string;
};
