import React from 'react';
import { Fade } from 'src/animations';
import { Section, TagList } from 'src/component';
import { content } from 'src/config';
import { componentSizes } from 'src/types';
import { Text } from 'src/widget';
import classes from './MyDetail.module.scss';

const UnmemoMyDetail: React.FC = () => {
  const { technologies } = content.about.myDetails;

  return (
    <Section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className={classes.info}>
              <Text size={componentSizes.large} className={classes.text}>
                <Fade>
                  I'm a front-end developer based in Karachi, Pakistan with over
                  4 years of experience in web development. I have a passion for
                  creating beautiful, responsive websites and web applications.
                </Fade>
              </Text>
              <Text size={componentSizes.medium} className={classes.text}>
                <Fade>
                  I graduated in 2019 with a degree in Software Engineering and
                  achieved a silver medal for securing the 2nd position in my
                  batch. Since then, I have been developing a wide range of
                  websites using technologies such as HTML, CSS, JavaScript,
                  jQuery, PHP, AJAX Bootstrap, WordPress, ReactJS, TypeScript,
                  and NodeJS.
                </Fade>
              </Text>
              <Text size={componentSizes.medium} className={classes.text}>
                <Fade>
                  My expertise includes WordPress theme development, landing
                  page design, and custom web applications. I take pride in
                  delivering high-quality work, and I'm always eager to take on
                  new challenges and push my skills to the next level.
                </Fade>
              </Text>
            </div>
          </div>
          <div className="col-lg-4">
            <div className={classes.technologies}>
              {technologies.map(({ name, detail }, index) => (
                <div className={classes.list} key={index}>
                  <Text
                    key={index}
                    size={componentSizes.small}
                    className={classes.title}
                  >
                    <Fade>{name}</Fade>
                  </Text>
                  <TagList tags={detail} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const MyDetail = React.memo(UnmemoMyDetail);
