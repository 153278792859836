import React from 'react';
import { separateLetterTransition } from 'src/utils';
import classes from './AnimatedText.module.scss';

type Props = {
  text: string;
  animate: boolean;
  hasPadding?: boolean;
};

const UnmemoAnimatedText: React.FC<Props> = ({
  text,
  animate = false,
  hasPadding = false
}) => (
  <span
    className={`${classes.btnText} ${animate ? classes.animate : ''} ${
      hasPadding ? classes.paddingBottom : ''
    }`}
  >
    <span className={classes.text}>{separateLetterTransition(text)}</span>
    <span className={classes.animText}>
      {separateLetterTransition(text)}
    </span>{' '}
  </span>
);

export const AnimatedText = React.memo(UnmemoAnimatedText);
