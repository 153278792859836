import React, { useEffect, useState } from 'react';
import { icons, images, pageLinks, person } from 'src/config';
import { useScroll } from 'src/hooks';
import { componentSizes } from 'src/types';
import { Icon, Image, Link, Text } from 'src/widget';
import classes from './Header.module.scss';
import { Menu } from './components';

const UnmemoHeader: React.FC = () => {
  const { isAtTop } = useScroll();
  const [isActive, setIsActive] = useState(false);

  const [hide, setHide] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (hasMounted) {
      setHide(true);
      setTimeout(() => {
        setHide(false);
      }, 800);
    } else {
      setHasMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <header
      className={`${classes.header} ${!isAtTop ? classes.scroll : ''} ${
        hide ? classes.hide : ''
      }`}
    >
      <div className="container">
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <div className={classes.email}>
              <Icon src={icons.email} />
              <Text size={componentSizes.regular} className={classes.text}>
                <a href={`mailto:${person.email}`}>{person.email}</a>
              </Text>
            </div>
          </div>
          <div className={classes.center}>
            <Link to={pageLinks.home}>
              <Image src={images.logo} alt="" />
            </Link>
          </div>
          <div className={classes.right}>
            <Link to={pageLinks.home}>
              <Image src={images.logo} alt="" />
            </Link>
            <div
              className={`${classes.menuIcon} ${isActive ? classes.cross : ''}`}
              onClick={() => {
                setIsActive(!isActive);
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <Menu isActive={isActive} />
    </header>
  );
};

export const Header = React.memo(UnmemoHeader);
