import React, { useState } from 'react';

import { useInView } from 'react-hook-inview';
import classes from './Fade.module.scss';

type Props = { children: React.ReactNode };

const UnmemoFade: React.FC<Props> = ({ children }) => {
  const [classFade, setClassFade] = useState(classes.fade);

  const [ref] = useInView({
    onEnter: () => setClassFade(`${classes.fade} ${classes.inView}`),
    onLeave: () => {},
    threshold: 1
  });

  return (
    <span className={classFade} ref={ref}>
      {children}
    </span>
  );
};

export const Fade = React.memo(UnmemoFade);
