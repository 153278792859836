import React from 'react';
import { BubblyLink, BubblyLinkProps } from 'react-bubbly-transitions';

type Props = BubblyLinkProps & {
  children: React.ReactNode;
};

const UnmemoLink: React.FC<Props> = ({ children, ...rest }) => (
  <BubblyLink colorStart="#c7003b" colorEnd="#fceeea" {...rest}>
    {children}
  </BubblyLink>
);

export const Link = React.memo(UnmemoLink);
