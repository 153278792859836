import React from 'react';
import { Fade } from 'src/animations';
import { htmlTag } from 'src/types';
import { Text } from 'src/widget';
import classes from './TagList.module.scss';

type Props = {
  tags: string[];
};

const UnmemoTagList: React.FC<Props> = ({ tags }) => {
  return (
    <Fade>
      <div className={classes.tags}>
        {tags.map((tag, index) => (
          <Text className={classes.tag} tag={htmlTag.tag} key={index}>
            {tag}
          </Text>
        ))}
      </div>
    </Fade>
  );
};

export const TagList = React.memo(UnmemoTagList);
