import React from 'react';
import { ClientFeedback, componentSizes, htmlTag } from 'src/types';
import { Image, Text } from 'src/widget';
import classes from './Testimonial.module.scss';

const UnmemoTestimonial: React.FC<ClientFeedback> = ({
  image,
  name,
  designation,
  company,
  message
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.image}>
          <Image src={image} alt={`${name}${company ? ` - ${company}` : ''}`} />
        </div>
        <div className={classes.detail}>
          <Text
            tag={htmlTag.p}
            weight="bold"
            size={componentSizes.medium}
            className={classes.name}
          >
            {name}
          </Text>
          <Text tag={htmlTag.p} size={componentSizes.small}>
            {designation} {`${company ? `| ${company}` : ''}`}
          </Text>
        </div>
      </div>
      <Text tag={htmlTag.p} className={classes.message}>
        {message}
      </Text>
    </div>
  );
};

export const Testimonial = React.memo(UnmemoTestimonial);
