import React from 'react';
import { Fade } from 'src/animations';
import { PageTitle, Section } from 'src/component';
import { Text } from 'src/widget';
import classes from './ContactHero.module.scss';

const UnmemoContactHero: React.FC = () => {
  return (
    <Section isFullHeight>
      <div className="container">
        <div className={classes.wrapper}>
          <div className="row">
            <div className="col-lg-8">
              <PageTitle
                title={'Contact'}
                subTitle="I create beautiful, responsive websites using a variety of technologies, including HTML, CSS, and JavaScript. From simple landing pages to complex e-commerce platforms, I can help bring your vision to life."
                hasMarginBottom
              />
              <Fade>
                <Text>
                  Send me an email at{' '}
                  <a href="mailto:hello@ishahasan.com">hello@ishahasan.com</a>{' '}
                  or contact me via{' '}
                  <a href="tel:(+92) 323 761 1606">(+92) 323 761 1606</a>
                </Text>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const ContactHero = React.memo(UnmemoContactHero);
