import React from 'react';
import { useInView } from 'react-hook-inview';
import Slider, { Settings as SliderProps } from 'react-slick';
import { Section, SectionTitle } from 'src/component';
import { content } from 'src/config';
import classes from './MyClients.module.scss';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { background } from 'src/utils';
import { Logos } from './Logos';
import { Testimonial } from './Testimonial';

const UnmemoMyClients: React.FC = () => {
  const { title, feedback } = content.home.myClients;

  const settings: SliderProps = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true
  };

  const [ref] = useInView({
    onEnter: () => background.change(),
    onLeave: () => background.revert(),
    threshold: 0.5
  });

  return (
    <Section>
      <div className="container" ref={ref}>
        <div className="row">
          <div className="col-lg-5">
            <SectionTitle title={title} />
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6">
            <div className={classes.sliderWrapper}>
              <Slider
                {...settings}
                className={`${classes.slider} myclient-slider`}
              >
                {feedback.map(({ ...rest }, index) => (
                  <Testimonial {...rest} key={index} />
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className={classes.logos}>
          <Logos />
        </div>
      </div>
    </Section>
  );
};

export const MyClients = React.memo(UnmemoMyClients);
