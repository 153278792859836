import React from 'react';
import { Link } from 'src/widget';
import classes from './PrimaryLink.module.scss';

type Props = {
  text: string;
  href: string;
  useLink?: boolean;
  target?: string;
};

const UnmemoPrimaryLink: React.FC<Props> = ({
  text,
  href,
  useLink = true,
  target
}) => {
  if (useLink)
    return (
      <Link to={href}>
        <span className={classes.link}>
          {text}
          <span className={classes.underline}></span>
        </span>
      </Link>
    );

  return (
    <a className={classes.a} href={href} target={target}>
      <span className={classes.link}>
        {text}
        <span className={classes.underline}></span>
      </span>
    </a>
  );
};

export const PrimaryLink = React.memo(UnmemoPrimaryLink);
