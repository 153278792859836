import React from 'react';
import { Image } from 'src/widget';

import classes from './Icon.module.scss';

type Props = {
  src: string;
};

const UnmemoIcon: React.FC<Props> = ({ src }) => {
  return <Image src={src} alt={''} className={classes.icon} />;
};

export const Icon = React.memo(UnmemoIcon);
