import React from 'react';
import { GradientCircle } from 'src/component';
import { HtmlTag, htmlTag } from 'src/types';
import classes from './HeavyText.module.scss';

type Props = {
  children: React.ReactNode;
  tag?: HtmlTag;
  className?: string;
  lines?: 'one-line' | 'two-line';
  size?: 'small' | 'regular';
};

const UnmemoHeavyText: React.FC<Props> = ({
  children,
  tag = htmlTag.p,
  className = '',
  lines = 'one-line',
  size = 'regular'
}) => {
  const classesProps = `${classes.heavyText} ${className} ${
    classes[`${size}Size`]
  }`;

  if (tag === htmlTag.p)
    return (
      <p className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </p>
    );

  if (tag === htmlTag.h1)
    return (
      <h1 className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </h1>
    );

  if (tag === htmlTag.h2)
    return (
      <h2 className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </h2>
    );

  if (tag === htmlTag.h3)
    return (
      <h3 className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </h3>
    );

  if (tag === htmlTag.h4)
    return (
      <h4 className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </h4>
    );

  if (tag === htmlTag.h5)
    return (
      <h5 className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </h5>
    );

  if (tag === htmlTag.h6)
    return (
      <h6 className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </h6>
    );

  if (tag === htmlTag.div)
    return (
      <div className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </div>
    );

  if (tag === htmlTag.span)
    return (
      <span className={classesProps}>
        {children}
        <div className={classes.circle}>
          <GradientCircle size={lines} />
        </div>
      </span>
    );

  return (
    <p className={classesProps}>
      {children}
      <div className={classes.circle}>
        <GradientCircle size={lines} />
      </div>
    </p>
  );
};

export const HeavyText = React.memo(UnmemoHeavyText);
