import React from 'react';
import { Fade } from 'src/animations';
import { SocialIcons } from 'src/component';
import { footer } from 'src/config';
import { componentSizes } from 'src/types';
import { Text } from 'src/widget';
import classes from './SubFooter.module.scss';

const UnmemoSubFooter: React.FC = () => {
  return (
    <div className={classes.subFooter}>
      <div className={classes.left}>
        <Text size={componentSizes.small} weight="light">
          <Fade>{footer.copyRight}</Fade>
        </Text>
      </div>
      <div className={classes.right}>
        <Fade>
          <SocialIcons />
        </Fade>
      </div>
    </div>
  );
};

export const SubFooter = React.memo(UnmemoSubFooter);
