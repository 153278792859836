import { BubblyContainer } from 'react-bubbly-transitions';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import { Post } from 'src/component';
import { blogList, blogs, pageLinks } from 'src/config';
import { About, Blog, Contact, Home, NotFound } from 'src/pages';

import 'src/sass/index.scss';

export default function Web() {
  return (
    <BrowserRouter>
      <BubblyContainer />
      <Routes>
        <Route path={pageLinks.home} element={<Home />}>
          <Route index element={<Home />} />
        </Route>

        <Route path={pageLinks.about} element={<About />}>
          <Route element={<About />} />
        </Route>

        <Route path={pageLinks.contact} element={<Contact />}>
          <Route element={<Contact />} />
        </Route>

        <Route path={pageLinks.blog} element={<Blog />}>
          <Route element={<Blog />} />
        </Route>

        {blogList.map((blog, i) => (
          <Route
            path={blogs[blog].link}
            element={<Post {...blogs[blog]} />}
            key={i}
          >
            <Route element={<Post {...blogs[blog]} />} />
          </Route>
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<Web />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
