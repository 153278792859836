import React from 'react';
import { Fade } from 'src/animations';
import { Section, SectionTitle } from 'src/component';
import { content, pageLinks, person } from 'src/config';
import { PrimaryButton, PrimaryLink } from 'src/widget';
import { Highlights } from './Highlights';
import classes from './WhoIAm.module.scss';

const UnmemoWhoIAm: React.FC = () => {
  const { title, description, primaryCTA, primaryLink, highlightPoints } =
    content.home.whoIAm;

  return (
    <Section>
      <div className="container">
        <div className={classes.wrapper}>
          <div className="row">
            <div className="col-lg-6">
              <SectionTitle
                title={title}
                subTitle={description}
                hasMarginBottom
              />

              <Fade>
                <div className={classes.btn}>
                  <PrimaryButton
                    text={primaryCTA}
                    href={`mailto:${person.email}`}
                    icon={{ name: 'mail' }}
                    className={classes.primary}
                  />
                  <PrimaryLink text={primaryLink} href={pageLinks.about} />
                </div>
              </Fade>
            </div>
            <div className="col-lg-6">
              <div className={classes.highlights}>
                <div className="row">
                  {highlightPoints.map(({ ...rest }, index) => (
                    <div className="col-6" key={index}>
                      <Fade>
                        <Highlights {...rest} />
                      </Fade>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const WhoIAm = React.memo(UnmemoWhoIAm);
