type SeparateLetterTransition = (text: string) => React.ReactNode;

export const separateLetterTransition: SeparateLetterTransition = text => {
  const wordsArray = Array.from(text);

  return (
    <>
      {wordsArray.map((word, index) => (
        <span className="outer" key={index}>
          <span
            className="inner"
            style={{ transitionDelay: `${(index + 1) / 100}s` }}
          >
            {word === ' ' ? <>&nbsp;</> : word}
          </span>
        </span>
      ))}
    </>
  );
};
