import React from 'react';

import { ComponentSize, HtmlTag, componentSizes, htmlTag } from 'src/types';
import classes from './Text.module.scss';

type Props = {
  children: React.ReactNode;
  tag?: HtmlTag;
  size?: ComponentSize;
  weight?: 'light' | 'regular' | 'medium' | 'semibold' | 'bold';
  isWhite?: boolean;
  className?: string;
};

const UnmemoText: React.FC<Props> = ({
  isWhite = false,
  children,
  tag = htmlTag.p,
  size = componentSizes.medium,
  weight = 'light',
  className = ''
}) => {
  if (tag === htmlTag.tag)
    return <span className={`${className} ${classes.tag}`}>{children}</span>;

  const allProps = {
    className: `${className} ${classes.text} ${
      isWhite ? classes.isWhite : ''
    } ${
      tag === htmlTag.p
        ? `${classes[`${size}Size`]} ${classes[`${weight}Weight`]}`
        : ''
    }`
  };

  if (tag === htmlTag.p) return <p {...allProps}>{children}</p>;

  if (tag === htmlTag.h1) return <h1 {...allProps}>{children}</h1>;

  if (tag === htmlTag.h2) return <h2 {...allProps}>{children}</h2>;

  if (tag === htmlTag.h3) return <h3 {...allProps}>{children}</h3>;

  if (tag === htmlTag.h4) return <h4 {...allProps}>{children}</h4>;

  if (tag === htmlTag.h5) return <h5 {...allProps}>{children}</h5>;

  if (tag === htmlTag.h6) return <h6 {...allProps}>{children}</h6>;

  if (tag === htmlTag.div) return <div {...allProps}>{children}</div>;

  return <p {...allProps}>{children}</p>;
};

export const Text = React.memo(UnmemoText);
