import React from 'react';
import { Fade } from 'src/animations';
import { htmlTag } from 'src/types';
import { HeavyText, Text } from 'src/widget';
import classes from './PageTitle.module.scss';

type Props = {
  title: string;
  subTitle?: string;
  hasMarginBottom?: boolean;
};

const UnmemoPageTitle: React.FC<Props> = ({
  title,
  subTitle,
  hasMarginBottom = false
}) => (
  <div
    className={`${classes.wrapper} ${
      hasMarginBottom ? classes.hasMarginBottom : ''
    } ${subTitle ? classes.hasSubTitle : ''}`}
  >
    <HeavyText tag={htmlTag.h1}>{title}</HeavyText>
    {subTitle && (
      <Fade>
        <Text>{subTitle}</Text>
      </Fade>
    )}
  </div>
);

export const PageTitle = React.memo(UnmemoPageTitle);
