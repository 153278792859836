import React, { useState } from 'react';
import { AnimatedText } from 'src/widget';

import classes from './PrimaryButton.module.scss';

type Props = {
  text: string;
  href: string;
  className?: string;
  icon?: {
    name: 'contact' | 'mail';
  };
};

const UnmemoPrimaryButton: React.FC<Props> = ({
  text,
  href,
  icon,
  className
}) => {
  const [isHovered, setHovered] = useState(false);

  const onHover = () => setHovered(true);
  const outHover = () => setHovered(false);

  return (
    <a href={href}>
      <span
        className={`${classes.button} ${
          icon ? `${classes.hasIcon} ${classes[icon.name]}` : ''
        } ${className}`}
        onMouseEnter={onHover}
        onMouseLeave={outHover}
      >
        <AnimatedText text={text} animate={isHovered} />
      </span>
    </a>
  );
};

export const PrimaryButton = React.memo(UnmemoPrimaryButton);
