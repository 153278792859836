import React from 'react';
import { Layout } from 'src/Layout';
import { Section } from 'src/component';
import { pageLinks, person } from 'src/config';
import { componentSizes } from 'src/types';
import { HeavyText, PrimaryLink, Text } from 'src/widget';

import classes from './NotFound.module.scss';

const UnmemoNotFound: React.FC = () => (
  <Layout
    title={`404 - ${person.name}`}
    description="I&#039;m Isha Hasan, Front-end Developer based in Karachi, Pakistan. I created many projects over the years. I focus on code quality and best practices."
    keywords="front-end developer, react developer, web developer, freelance developer"
  >
    <Section isFullHeight>
      <div className="container">
        <HeavyText className={classes.heavyText}>404</HeavyText>
        <Text
          size={componentSizes.large}
          weight={'semibold'}
          className={classes.text}
        >
          Page not found
        </Text>
        <div className={classes.detail}>
          <Text size={componentSizes.regular}>
            The requested page is missing!
          </Text>
          <PrimaryLink href={pageLinks.home} text="Go Back Home" />
        </div>
      </div>
    </Section>
  </Layout>
);

export const NotFound = React.memo(UnmemoNotFound);
