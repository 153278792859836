import React from 'react';
import { Services, componentSizes, htmlTag } from 'src/types';
import { Image, Text } from 'src/widget';
import classes from './Service.module.scss';

const UnmemoService: React.FC<Services> = ({ icon, title, description }) => {
  return (
    <div className={classes.service}>
      <div className={classes.icon}>
        <Image src={icon} alt={title} />
      </div>
      <Text tag={htmlTag.h3} className={classes.title}>
        {title}
      </Text>
      <Text
        tag={htmlTag.p}
        size={componentSizes.regular}
        className={classes.description}
      >
        {description}
      </Text>
      {/* <TagList tags={tags} /> */}
    </div>
  );
};

export const Service = React.memo(UnmemoService);
