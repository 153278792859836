import React from 'react';
import { Highlight, componentSizes } from 'src/types';
import { Text } from 'src/widget';
import classes from './Highlights.module.scss';

const UnmemoHighlights: React.FC<Highlight> = ({
  value,
  title,
  description
}) => (
  <div className={classes.wrapper}>
    <Text size={componentSizes.large} weight="bold" className={classes.value}>
      {value}
    </Text>
    <Text
      size={componentSizes.medium}
      weight="medium"
      className={classes.title}
    >
      {title}
    </Text>
    <Text size={componentSizes.small}>{description}</Text>
  </div>
);

export const Highlights = React.memo(UnmemoHighlights);
