import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatedText, Link } from 'src/widget';
import classes from './NavLink.module.scss';

type Props = {
  to: string;
  text: string;
  hasPadding?: boolean;
};

const UnmemoNavLink: React.FC<Props> = ({ to, text, ...rest }) => {
  const [isHovered, setHovered] = useState(false);
  const location = useLocation();

  const onHover = () => setHovered(true);
  const outHover = () => setHovered(false);

  return (
    <Link to={to}>
      <span
        className={`${classes.span} ${
          location.pathname === to ? classes.active : ''
        }`}
        onMouseEnter={onHover}
        onMouseLeave={outHover}
      >
        <AnimatedText text={text} animate={isHovered} {...rest} />
      </span>
    </Link>
  );
};

export const NavLink = React.memo(UnmemoNavLink);
