import React from 'react';
import Slider, { Settings as SliderProps } from 'react-slick';
import { content } from 'src/config';
import classes from './Logos.module.scss';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Image } from 'src/widget';

const UnmemoLogos: React.FC = () => {
  const { logo } = content.home.myClients;

  const settings: SliderProps = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    centerMode: true,
    cssEase: 'linear',
    pauseOnHover: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <Slider {...settings} className="logo-slider">
      {logo.map(({ name, image }, index) => (
        <div className={classes.image} key={index}>
          <Image src={image} alt={name} />
        </div>
      ))}
    </Slider>
  );
};

export const Logos = React.memo(UnmemoLogos);
