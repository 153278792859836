import React from 'react';
import { Blog, icons } from 'src/config';
import { componentSizes } from 'src/types';
import { Icon, Image, Link, Text } from 'src/widget';
import classes from './PostLink.module.scss';

const UnmemoPostLink: React.FC<Blog> = ({
  title,
  description,
  date,
  image,
  link
}) => {
  return (
    <div className={classes.post}>
      <div className={classes.thumbnail}>
        <Image src={image} alt={title} />
      </div>
      <div className={classes.content}>
        <Link to={link}>
          <Text className={classes.title}>{title}</Text>
        </Link>
        <Text size={componentSizes.small} className={classes.description}>
          {description}
        </Text>
        <div className={classes.icon}>
          <Icon src={icons.date} />
          <Text size={componentSizes.small}>{date}</Text>
        </div>
      </div>
    </div>
  );
};

export const PostLink = React.memo(UnmemoPostLink);
