import React from 'react';
import { icons, socialLinks } from 'src/config';
import { Icon } from 'src/widget';
import classes from './SocialIcons.module.scss';

const UnmemoSocialIcons: React.FC = () => {
  return (
    <div className={classes.socialLinks}>
      <div className={classes.link}>
        <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
          <Icon src={icons.facebook} />
        </a>
      </div>
      <div className={classes.link}>
        <a href={socialLinks.linkedIn} target="_blank" rel="noreferrer">
          <Icon src={icons.linkedin} />
        </a>
      </div>
      {/* <div className={classes.link}>
        <a href={socialLinks.whatsapp}>
          <Icon src={icons.whatsapp} />
        </a>
      </div> */}
      <div className={classes.link}>
        <a href={socialLinks.skype} target="_blank" rel="noreferrer">
          <Icon src={icons.skype} />
        </a>
      </div>
      {/* <div className={classes.link}>
        <a href={socialLinks.gmail}>
          <Icon src={icons.gmail} />
        </a>
      </div> */}
    </div>
  );
};

export const SocialIcons = React.memo(UnmemoSocialIcons);
