import React from 'react';
import { PageTitle, Section } from 'src/component';

const UnmemoBlogHero: React.FC = () => {
  return (
    <Section>
      <div className={`container`}>
        <div className="row">
          <div className="col-lg-8">
            <PageTitle
              title={'Blog'}
              subTitle="I create beautiful, responsive websites using a variety of technologies, including HTML, CSS, and JavaScript. From simple landing pages to complex e-commerce platforms, I can help bring your vision to life."
              hasMarginBottom
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export const BlogHero = React.memo(UnmemoBlogHero);
