import React from 'react';
import classes from './Section.module.scss';

type Props = {
  children: React.ReactNode;
  id?: string;
  isFullHeight?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
};

const UnmemoSection: React.FC<Props> = ({
  children,
  isFullHeight = false,
  noPaddingTop = false,
  noPaddingBottom = false,
  ...rest
}) => (
  <section
    className={`${classes.wrapper} ${
      isFullHeight ? `${classes.fullHeight}` : ``
    } ${noPaddingTop ? classes.noPaddingTop : ''} ${
      noPaddingBottom ? classes.noPaddingBottom : ''
    }`}
    {...rest}
  >
    {children}
  </section>
);

export const Section = React.memo(UnmemoSection);
