import React from 'react';
import { Layout } from 'src/Layout';
import { person } from 'src/config';
import { AboutHero, MyClients, MyDetail } from 'src/section';

const UnmemoAbout: React.FC = () => (
  <Layout
    title={`About - ${person.name}`}
    description="I&#039;m Isha Hasan, Front-end Developer based in Karachi, Pakistan. I created many projects over the years. I focus on code quality and best practices."
    keywords="front-end developer, react developer, web developer, freelance developer"
  >
    <AboutHero />
    <MyDetail />
    <MyClients />
  </Layout>
);

export const About = React.memo(UnmemoAbout);
