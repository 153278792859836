import React from 'react';
import { Layout } from 'src/Layout';
import { person } from 'src/config';
import { BlogHero, BlogPostList } from 'src/section';

const UnmemoBlog: React.FC = () => (
  <Layout
    title={`Blog - ${person.name}`}
    description="I&#039;m Isha Hasan, Front-end Developer based in Karachi, Pakistan. I created many projects over the years. I focus on code quality and best practices."
    keywords="front-end developer, react developer, web developer, freelance developer"
  >
    <BlogHero />
    <BlogPostList />
  </Layout>
);

export const Blog = React.memo(UnmemoBlog);
