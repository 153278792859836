import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { CustomCursor, Footer, Header } from 'src/component';
import { SEOProperties } from 'src/types';

type Props = {
  children: React.ReactNode;
} & SEOProperties;

const UnmemoLayout: React.FC<Props> = ({
  children,
  title,
  description,
  keywords
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:url" content={`https://ishahasan.com${pathname}`} />
        <link rel="canonical" href={`https://ishahasan.com${pathname}`} />

        {/* Add more meta tags as needed */}
      </Helmet>

      <CustomCursor />
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export const Layout = React.memo(UnmemoLayout);
