import React from 'react';
import { PostLink, Section } from 'src/component';

import { blogList, blogs } from 'src/config';
import classes from './BlogPostList.module.scss';

const UnmemoBlogPostList: React.FC = () => {
  return (
    <Section noPaddingTop>
      <div className={`container`}>
        <div className={classes.postList}>
          {blogList.map((blog, i) => (
            <PostLink key={i} {...blogs[blog]} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export const BlogPostList = React.memo(UnmemoBlogPostList);
