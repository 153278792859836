import React from 'react';
import { PageTitle, Section } from 'src/component';
import { content, person } from 'src/config';
import { PrimaryButton, PrimaryLink } from 'src/widget';
import classes from './AboutHero.module.scss';

import { Fade } from 'src/animations';
import resume from 'src/assets/ishahasan_resume.pdf';

const UnmemoAboutHero: React.FC = () => {
  const { title, primaryCTA, primaryLink } = content.about.hero;

  return (
    <Section isFullHeight>
      <div className={`container ${classes.wrapper}`}>
        <div className="row">
          <div className="col-lg-8">
            <PageTitle
              title={title}
              subTitle="I create beautiful, responsive websites using a variety of technologies, including HTML, CSS, and JavaScript. From simple landing pages to complex e-commerce platforms, I can help bring your vision to life."
              hasMarginBottom
            />
          </div>
        </div>
        <Fade>
          <div className={classes.btn}>
            <PrimaryButton
              text={primaryCTA}
              href={`mailto:${person.email}`}
              icon={{ name: 'contact' }}
              className={classes.primary}
            />
            <PrimaryLink text={primaryLink} href={resume} useLink={false} />
          </div>
        </Fade>
      </div>
    </Section>
  );
};

export const AboutHero = React.memo(UnmemoAboutHero);
