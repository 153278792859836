import React from 'react';
import { useMousePosition } from 'src/hooks';
import classes from './CustomCursor.module.scss';

const UnmemoCustomCursor: React.FC = () => {
  const { clientX, clientY } = useMousePosition();

  return (
    <div
      className={classes.cursor}
      style={{
        left: clientX,
        top: clientY
      }}
    >
      <div></div>
      {/* <GradientCircle /> */}
    </div>
  );
};

export const CustomCursor = React.memo(UnmemoCustomCursor);
