import React, { useState } from 'react';
import { SubFooter } from 'src/component';
import { footer, person } from 'src/config';
import { componentSizes, htmlTag } from 'src/types';
import { AnimatedText, HeavyText, Text } from 'src/widget';

import { Fade } from 'src/animations';
import classes from './Footer.module.scss';

const UnmemoFooter: React.FC = () => {
  const [isHovered, setHovered] = useState(false);

  const onHover = () => setHovered(true);
  const outHover = () => setHovered(false);

  return (
    <footer className={classes.footer}>
      <div className="container">
        <div className={classes.wrapper}>
          <Text size={componentSizes.large} weight="light">
            <Fade>{footer.title}</Fade>
          </Text>
          <a
            href={`mailto:${person.email}`}
            onMouseEnter={onHover}
            onMouseLeave={outHover}
          >
            <Fade>
              <HeavyText tag={htmlTag.span}>
                <AnimatedText text={footer.button} animate={isHovered} />
              </HeavyText>
            </Fade>
          </a>
        </div>
        <SubFooter />
      </div>
    </footer>
  );
};

export const Footer = React.memo(UnmemoFooter);
